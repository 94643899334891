import React from "react";
import { Link } from "react-router-dom";

export default function Logo() {
  return (
    <Link to="/" className="inline-flex" aria-label="Bata">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="130"
        height="28"
        viewBox="0 0 130 28"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.799189 10.0025L7.75579 13.9996L16.6089 8.91297V0L0 9.54352L0.799189 10.0025Z"
          fill="#D9B9F3"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.799189 17.9966L7.75579 13.9995L16.6089 19.0861V27.9991L0 18.4556L0.799189 17.9966Z"
          fill="#4558C8"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.8451 10.0025L29.8885 13.9996L21.0354 8.91297V0L37.6443 9.54352L36.8451 10.0025Z"
          fill="#EE5E37"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.8451 17.9966L29.8885 13.9995L21.0354 19.0861V27.9991L37.6443 18.4556L36.8451 17.9966Z"
          fill="#E0F47E"
        />
        <path
          d="M48.8983 0H59.3141C64.46 0 70.186 2.4368 70.186 7.80487C70.186 10.3651 68.8993 12.2652 66.9909 13.5863C69.5219 15.073 70.8085 17.3442 70.8085 19.5747C70.8085 23.5389 67.0742 27.5867 59.3141 27.5867H48.8983V0ZM56.1598 5.12042V11.7276H58.4419C61.4297 11.7276 62.9653 10.0346 62.9653 8.42358C62.9653 6.81257 61.4714 5.11957 58.4419 5.11957H56.1598V5.12042ZM63.5878 18.9552C63.5046 17.3036 61.7618 15.6926 58.4428 15.6926H56.1607V22.4654H58.4428C61.9699 22.4654 63.671 20.6896 63.5878 18.9552Z"
          fill="#191B1E"
        />
        <path
          d="M80.144 14.3714C82.0116 14.3714 83.8784 14.9082 85.2899 15.941V15.5691C85.2899 13.0089 83.1319 11.976 80.7666 11.976C78.6501 11.976 76.3264 12.8018 75.123 14.0409L72.4256 8.42438C74.7909 7.22669 77.9027 6.52515 80.9322 6.52515C86.8246 6.52515 92.5514 9.2096 92.5514 16.2301V27.5866H87.2815L85.9948 25.6874C84.5425 27.2156 82.3429 28 80.144 28C76.2432 28 72.3016 25.6874 72.3016 21.0209C72.3016 16.602 76.244 14.3723 80.144 14.3723V14.3714ZM84.833 20.5661C84.833 19.7403 83.7128 18.8731 82.3429 18.8731C80.6417 18.8731 79.6039 19.7403 79.6039 20.649C79.6039 21.5576 80.6825 22.3834 82.3429 22.3834C83.7535 22.3834 84.833 21.4748 84.833 20.5661Z"
          fill="#191B1E"
        />
        <path
          d="M105.082 27.9991C100.559 27.9991 96.4505 25.2327 96.4505 19.038V12.5128H94.4589V6.93762H97.1979C100.186 6.60714 99.3976 4.04693 101.057 1.73438H103.713V6.93762H107.572V12.5128H103.713V19.2028C103.713 20.8958 104.958 21.3911 106.244 21.3911C106.617 21.3911 106.99 21.3497 107.364 21.2677L108.194 27.5858C107.613 27.7506 106.409 27.9991 105.082 27.9991Z"
          fill="#191B1E"
        />
        <path
          d="M117.53 14.3714C119.398 14.3714 121.264 14.9082 122.676 15.941V15.5691C122.676 13.0089 120.518 11.976 118.153 11.976C116.036 11.976 113.713 12.8018 112.509 14.0409L109.812 8.42438C112.177 7.22669 115.289 6.52515 118.318 6.52515C124.211 6.52515 129.938 9.2096 129.938 16.2301V27.5866H124.668L123.381 25.6874C121.929 27.2156 119.729 28 117.53 28C113.629 28 109.688 25.6874 109.688 21.0209C109.688 16.602 113.63 14.3723 117.53 14.3723V14.3714ZM122.219 20.5661C122.219 19.7403 121.099 18.8731 119.729 18.8731C118.028 18.8731 116.99 19.7403 116.99 20.649C116.99 21.5576 118.069 22.3834 119.729 22.3834C121.14 22.3834 122.219 21.4748 122.219 20.5661Z"
          fill="#191B1E"
        />
      </svg>
    </Link>
  );
}
