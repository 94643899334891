import React from "react";

const EditIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M20.1622 5.07637L18.9851 3.86348L19.6123 3.23067C19.8959 2.94942 20.3169 2.91426 20.5833 3.18673L20.7809 3.39766C21.073 3.6877 21.073 4.10958 20.7809 4.42598L20.1622 5.07637ZM9.82563 14.6652C9.66237 14.7356 9.49052 14.5334 9.55926 14.384L10.3326 12.7316L18.3493 4.53145L19.5264 5.71798L11.5011 13.9182L9.82563 14.6652ZM6.698 20.3606C4.91079 20.3606 4 19.4553 4 17.6359V7.51094C4 5.7004 4.91079 4.78633 6.698 4.78633H16.4332L15.0498 6.20137H6.72378C5.86454 6.20137 5.38337 6.67598 5.38337 7.59005V17.5656C5.38337 18.4797 5.86454 18.9455 6.72378 18.9455H16.7683C17.3697 18.9455 17.8423 18.4797 17.8423 17.5656V9.12813L19.2257 7.71309V17.6359C19.2257 19.4553 18.3321 20.3606 16.7854 20.3606H6.698Z"
        fill="black"
      />
    </svg>
  );
};

export default EditIcon;
