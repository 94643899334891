import React from "react";

const AdminHomePage = () => {
    return (
        <div>
        <h1>Admin Home</h1>
        </div>
    );
};

export default AdminHomePage;
