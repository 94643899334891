import React from "react";
import RegisterForm from "../components/auth/RegisterForm";
import "../styles/register.css";

const RegisterPage = () => {
    return (
        <RegisterForm />
    );
}

export default RegisterPage;