import { useCallback } from "react";

export const useSmoothScroll = () => {
  const smoothScroll = useCallback((id) => {
    const target = document.querySelector(id);
    if (target) {
      target.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  return smoothScroll;
};
