import React from 'react';
import LoginForm from '../components/auth/LoginForm';
import '../styles/login.css'

const LoginPage = () => {
  return (
      <LoginForm />
  );
};

export default LoginPage;
